var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-icon",
    _vm._g(
      _vm._b(
        {
          class: _vm.className,
          attrs: {
            right: _vm.right,
            left: _vm.left,
            size: _vm.size,
            color: _vm.color,
            dark: _vm.dark,
          },
        },
        "v-icon",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [_vm._v("\n  " + _vm._s(_vm.iconSvg) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }