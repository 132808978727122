import Vue from 'vue'
import { CodingInformationApiAdapter } from '../adapter/CodingInformationApiAdapter'
import { CodingInformation } from '../classes/CodingInformation'

export default {
    loadCodingInformation({commit}, id) {
        Vue.prototype.$http.get('api/v1/ssr/codingview/' + id, new CodingInformationApiAdapter)
            .subscribe((result: CodingInformation) => {
                commit('saveCodingInformation', result);
        });
    },
}
