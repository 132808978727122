/**
 * @package Mediboard\System
 * @author  SAS OpenXtrem <dev@openxtrem.com>
 * @license https://www.gnu.org/licenses/gpl.html GNU General Public License
 * @license https://www.openxtrem.com/licenses/oxol.html OXOL OpenXtrem Open License
 */

import {Component, Prop, Watch} from 'vue-property-decorator'
import OxFieldCore from "./OxFieldCore"
import OxIconCore from "../OxIcon/OxIconCore";

/**
 * OxFieldStrCore
 *
 * Composant-mod�le pour les composants de champ texte
 */
@Component
export default class OxFieldStrCore extends OxFieldCore {

  protected get messageClassName() {
    return {
      isInfo: this.state === 'info',
      isError: this.state === 'error'
    }
  }

  protected get labelClassName() {
    return {
      isError: this.state === 'error'
    }
  }

  protected get iconName() {
    return OxIconCore.get(this.icon)
  }
}
