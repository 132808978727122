/**
 * @author  Kheops Technologies SA <devops@kheops.ch>
 * @copyright Kheops Technologies SA
 */
import { SnackbarManager } from '@internal-libraries/kheops-ui-lib'
import { Component, InjectReactive, Vue } from 'vue-property-decorator'

@Component({
    inheritAttrs: false
})
export default class ClvrComponent extends Vue {
    @InjectReactive() snackbarManager!: SnackbarManager
}
