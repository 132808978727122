import { CodingInformation } from '../classes/CodingInformation'
import { ApiDataAdapter } from '@internal-libraries/kheops-ui-lib/src/services/RestClient/Interfaces/ApiDataAdapter'
import { get } from 'lodash'
import { CodingDashboardDto } from '../types/CodingDashboardDto'


export class CodingInformationApiAdapter implements ApiDataAdapter {
    createClass(apiData: CodingDashboardDto): CodingInformation {
        return new CodingInformation(
            get(apiData, 'title', ''),
            get(apiData, 'requiredPeriodMinutes', 0),
            get(apiData, 'isWeeklyAverage', false),
            get(apiData, 'rules'),
            get(apiData, 'stayPeriod'),
            get(apiData, 'periods')
        )
    }
}
