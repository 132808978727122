import VueFormulate from '@braid/vue-formulate'
import {FormBaseComponent} from '@internal-libraries/kheops-ui-lib'

export default function(formulateInstance: typeof VueFormulate): void {
    formulateInstance.extend({
        components: {
            FormBaseComponent
        },
        library: {
            ClvrPatientAutocomplete: {
                classification: 'select',
                component: 'FormBaseComponent'
            },
            ChainingManagementContext: {
                classification: 'text',
                component: 'FormBaseComponent'
            },
            KhCheckbox: {
                classification: 'button',
                component: 'FormBaseComponent'
            },
            ChainingManagementServiceList: {
                classification: 'text',
                component: 'FormBaseComponent'
            }
        }
    })
}
