/**
 * @package Mediboard\System
 * @author  SAS OpenXtrem <dev@openxtrem.com>
 * @license https://www.gnu.org/licenses/gpl.html GNU General Public License
 * @license https://www.openxtrem.com/licenses/oxol.html OXOL OpenXtrem Open License
 */

import OxProviderCore from "../OxProviderCore";
import { ApiTranslatedResponse, ApiConfigurationData } from "../../Models/ApiResponseModel";

/**
 * OxParametersProvider
 *
 * Provider de donn�es pour le gestionnaire de param�tres OxParametersApi, gestionnaire de spec pour OXSpecsApi
 *
 */
export default class OxParametersProvider extends OxProviderCore {
  public static async loadConfiguration(configuration: string): Promise<string|boolean> {
    const dataConfig = (await (new OxProviderCore)
      .getApi("mediboard/configuration", {configuration: configuration}) as ApiTranslatedResponse<any>)
    return (dataConfig.data as ApiConfigurationData).configuration
  }
  public static async loadGroupConfiguration(group_configuration: string): Promise<string|boolean> {
    return true
  }
  public async loadSpecs(specsUrl: string): Promise<any[]> {
    return (await super.getApi(specsUrl) as {data: any[]}).data
  }
}
