import Vue, {AsyncComponent, Component} from 'vue'
import OxVueApi from '../../modules/system/vue/Core/OxVueApi'
import OxVueWrap from '../../modules/system/vue/Core/OxVueWrap'
import KhVueSolo from '../../modules/system/vue/Core/KhVueSolo'
import * as vuetifyComponents from 'vuetify/lib'
import {polyfill} from 'es6-promise'
import {polyfillLoader} from 'polyfill-io-feature-detection'
import dynamicComponents from './dynamic-imports'
import ClvrAlertStoreModule from '../../modules/system/vue/Core/OxStores/Modules/Alert/ClvrAlertStoreModule'
import ClvrApiCacheStoreModule from '../../modules/system/vue/Core/OxStores/Modules/ApiCache/ClvrApiCacheStoreModule'
import ClvrEventsStoreModule from '../../modules/system/vue/Core/OxStores/Modules/Events/ClvrEventsStoreModule'
import ClvrFieldSpecificationsStoreModule
    from '../../modules/system/vue/Core/OxStores/Modules/FieldSpecifications/ClvrFieldSpecificationsStoreModule'
import ClvrGlobalConfigStoreModule
    from '../../modules/system/vue/Core/OxStores/Modules/GlobalConfig/ClvrGlobalConfigStoreModule'
import ClvrMainParametersStoreModule
    from '../../modules/system/vue/Core/OxStores/Modules/MainParameters/ClvrMainParametersStoreModule'
import ClvrSIHParametersStoreModule
    from '../../modules/system/vue/Core/OxStores/Modules/SIHParameters/ClvrSIHParametersStoreModule'
import I18NextLocalStorageBackend from 'i18next-localstorage-backend'
import I18NextHttpBackend from 'i18next-http-backend'
import {EmbeddedComponent} from './types/EmbeddedComponent'
import {KheopsUiLib} from '@internal-libraries/kheops-ui-lib'
import theme from './setting/theme'
import ClvrCodingInformationStoreModule from '../../modules/soins/vue/store/ClvrCodingInformationStoreModule'
import ChainingManagementContext from '../../modules/chaining/vue/Components/ChainingManagementContext';
import ClvrChainingStoreModule from '../../modules/chaining/vue/store/ClvrChainingStoreModule'
import ChainingManagementServiceList from '../../modules/chaining/vue/Components/ChainingManagementServiceList';
import ClvrFormPlugins from './ClvrFormPlugins'

// Import the mandatory styles
import '@mdi/font/css/materialdesignicons.css'
import '@internal-libraries/kheops-ui-lib/dist/kheops-ui-lib.css'

import './scss/theme.scss';
import { PermissionsService } from '../../core/classes/PermissionsService/PermissionsService'

const baseUrl = window.App.config.api_url || window.App.config.external_url

Vue.prototype.$updateHiddenField = function $updateHiddenField(componentData: unknown) {
    if (this.$root?.$options?.uniqId) {
        const element = document.getElementById('field-vuesjs-' + this.$root.$options.uniqId)
        if (element) {
            (<HTMLInputElement>element).value = btoa(unescape(encodeURIComponent(JSON.stringify(componentData))))
        }
    }
}

Vue.prototype.$permissionsService = new PermissionsService()

const kheopsUiLib = KheopsUiLib.init(Vue, {
    storeModules: {
        ...ClvrAlertStoreModule,
        ...ClvrApiCacheStoreModule,
        ...ClvrEventsStoreModule,
        ...ClvrFieldSpecificationsStoreModule,
        ...ClvrGlobalConfigStoreModule,
        ...ClvrMainParametersStoreModule,
        ...ClvrSIHParametersStoreModule,
        ...ClvrCodingInformationStoreModule,
        ...ClvrChainingStoreModule
    },
    i18n: {
        language: window.Preferences.LOCALE || 'fr',
        backendConfigurations: [
            {
                backend: I18NextHttpBackend,
                options: {
                    loadPath: baseUrl + (baseUrl.substr(-1, 1) !== '/' ? '/' : '') + 'api/v1/locales/{{lng}}/{{ns}}'
                }
            }
        ]
    },
    vuetify: {
        theme
    },
    httpClient: {
        baseUrl,
    },
    form: {
        plugin: ClvrFormPlugins
    }
})
//@todo move that in another file
Vue.component('ChainingManagementContext', ChainingManagementContext);
Vue.component('ChainingManagementServiceList', ChainingManagementServiceList);

/**
 * merge vuetify components and our components
 */
const modulesList = {...vuetifyComponents, ...dynamicComponents}

/**
 * External vue JS module injection
 */
window.buildEmbeddedComponent = ({
                                     uniq,
                                     content,
                                     data = {},
                                     methods = {},
                                     computed = {},
                                     dependencies = [],
                                     i18nNamespaces = [],
                                     type = "module"
                                 }: EmbeddedComponent): Vue => {

    let components: {
        [_: string]:
            | Component<unknown, unknown, unknown, unknown>
            | AsyncComponent<unknown, unknown, unknown, unknown>
    } = {}

    let tag: string

    // type of application
    if (type === "standalone") {
        components.KhVueSolo = KhVueSolo
        tag = "KhVueSolo"
    } else {
        components.OxVueWrap = OxVueWrap
        tag = "OxVueWrap"
    }

    // apply dependencies
    dependencies.forEach(e => {
        if (!components[e] && modulesList[e]) components[e] = modulesList[e]
    })

    if (i18nNamespaces) {
        kheopsUiLib.i18n.loadNamespaces(i18nNamespaces).then()
    }

    return new Vue({
        ...kheopsUiLib.plugins,
        el: "#component-vuejs-" + uniq,
        template: `
          <${tag}>${content}</${tag}>`,
        data: data,
        computed: computed,
        methods: methods,
        components: components,
        uniqId: uniq,
    })
}

window.initVueRoots = async container => {
    let vueRoots = container.querySelectorAll('.vue-root')
    if (vueRoots.length === 0) {
        return
    }

    polyfillLoader({
        features: 'Promise',
        onCompleted: polyfill,
    })

    const {Preferences, NodeList} = window

    if (NodeList && !NodeList.prototype.forEach) {
        NodeList.prototype.forEach = Array.prototype.forEach
    }

    // API base url
    const response = await fetch(
        baseUrl + (baseUrl.substr(-1, 1) !== '/' ? '/' : '') + "api/v1/locales"
    )
    const locales = await response.json()

    OxVueApi.init(Preferences, locales.data.attributes, baseUrl, 'api/').then()

    vueRoots.forEach((element) => {
        let vueComponent = element.getAttribute('vue-component')
        if (!vueComponent || !dynamicComponents[vueComponent]) {
            // Escaping the non-component containers
            return false
        }
        // Initializing the container id
        while (!element.id) {
            let tmpId = 'vue_container_' + Math.ceil(Math.random() * Math.pow(10, 10))
            element.id = document.getElementById(tmpId) ? '' : tmpId
        }
        let vueProps = ''
        for (let i = 0; i < element.attributes.length; i++) {
            let _attribute = element.attributes[i]
            let _attributeName = _attribute.nodeName
            if (_attributeName.indexOf('vue-') === -1 && _attributeName !== 'vue-root' && _attributeName.indexOf('data-') === -1) {
                continue
            }
            let nodeValue = (typeof _attribute.nodeValue === 'string') ?
                _attribute.nodeValue.replace(/'/g, '&#39;') :
                _attribute.nodeValue
            vueProps += _attributeName.replace(/^(vue|data)-/g, '') + "='" + nodeValue + "' "
        }

        return new Vue({
            ...kheopsUiLib.plugins,
            el: '#' + element.id,
            template: '<OxVueWrap><Comp ' + vueProps + '/></OxVueWrap>',
            components: {
                Comp: dynamicComponents[vueComponent],
                OxVueWrap,
            },
        })
    })
}

document.addEventListener('readystatechange', () => {
    if (document.readyState === 'complete') {
        window.initVueRoots(document).then()
    }
})
