export default {
    codingInformation: {
        title: 'no data loaded',
        requiredPeriodMinutes: 0,
        rules: {},
        stayPeriod: {
            messages: {
                both: ''
            }
        },
        periods: []
    }

}
