/**
 * @package Mediboard\System
 * @author  SAS OpenXtrem <dev@openxtrem.com>
 * @license https://www.gnu.org/licenses/gpl.html GNU General Public License
 * @license https://www.openxtrem.com/licenses/oxol.html OXOL OpenXtrem Open License
 */

import OxParametersApi from "./OxParametersApi/OxParametersApi"
import OxStoreCore from "./OxStores/OxStoreCore"

/**
 * OxVueApi
 *
 * Classe pilote des donn�es globales de l'application OxVue : Etat de chargement, url d'api, ...
 */
export default class OxVueApi {
  /**
   * Initialisation globale d'une zone VueJS
   */
  public static async init(preferences: any, locales: any[], externalUrl: string, apiUrlSuffix: string) {
    OxParametersApi.setPrefs(preferences)
    OxParametersApi.setTrs(locales)
    this.setUrl(externalUrl , apiUrlSuffix)
  }
  /**
   * Set des urls
   *
   * @param baseUrl string Url de base
   */
  public static setUrl(baseUrl: string, apiSuffix: string) {
    this.setRootUrl(baseUrl)
    this.setBaseUrl(baseUrl + (baseUrl.substr(-1, 1) !== '/' ? '/' : '') + apiSuffix)
  }

  /**
   * Set de l'url de base
   *
   * @param baseUrl string Url de base
   */
  public static setRootUrl(baseUrl: string) {
    OxStoreCore.commit('setRootUrl', baseUrl)
  }

  /**
   * Set de l'url de base
   *
   * @param baseUrl string Url de base
   */
  public static setBaseUrl(baseUrl: string) {
    OxStoreCore.commit('setBaseUrl', baseUrl)
  }

  /**
   * R�cup�ration de l'url de base
   *
   * @return string
   */
  public static getBaseUrl(): string {
    return OxStoreCore.getters.url
  }

  /**
   * R�cup�ration de l'url de base
   *
   * @return string
   */
  public static async getRootUrl(): Promise<string> {
    return OxStoreCore.getters.rooturl
  }

  /**
   * Mise en chargement de l'application
   */
  public static load() {
    OxStoreCore.commit('addLoading')
  }

  /**
   * D�sactivation du chargement de l'application
   */
  public static unload() {
    OxStoreCore.commit('removeLoading')
  }
  /**
   * R�cup�ration de l'�tat de chargement de l'application
   */
  public static loading() {
    return OxStoreCore.getters.loading
  }

}
