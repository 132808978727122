import OxVue from '../OxVue'
import { Component, ProvideReactive } from 'vue-property-decorator'
import { KhSnackbarManager, SnackbarManager } from '@internal-libraries/kheops-ui-lib'
import { Nullable } from '@internal-libraries/kheops-ui-lib/dist/types/common/types'

/**
 * KhVueSolo
 *
 * Wrapper g�n�ral de l'application Vue.
 */
@Component({
    components: {
        KhSnackbarManager
    }
})

export default class KhVueSolo extends OxVue {
    @ProvideReactive() snackbarManager: Nullable<SnackbarManager> = null
}
