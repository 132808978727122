var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { staticClass: "cleverh-v-app" },
    [
      _c(
        "v-main",
        [
          _vm._t("default"),
          _vm._v(" "),
          _c("kh-snackbar-manager", {
            attrs: { snackbar: _vm.snackbarManager },
            on: {
              "update:snackbar": function ($event) {
                _vm.snackbarManager = $event
              },
            },
            scopedSlots: _vm._u([
              {
                key: "deleteInfo",
                fn: function (ref) {
                  var snack = ref.snack
                  return [
                    _c(
                      "v-container",
                      [
                        _c("v-row", [
                          _c("span", [
                            _vm._v(_vm._s(snack.message.message) + " :"),
                          ]),
                        ]),
                        _vm._v(" "),
                        _vm._l(snack.message.items, function (item, index) {
                          return _c("v-row", { key: index }, [
                            _c("span", [_vm._v(_vm._s(item))]),
                          ])
                        }),
                      ],
                      2
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }