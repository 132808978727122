/**
 * @author  Kheops Technologies SA <devops@kheops.ch>
 * @copyright Kheops Technologies SA
 */
export default {
    'VueAtomes': () => import(/* webpackChunkName: "VueAtomes" */ '../../modules/dPdeveloppement/vue/VueAtomes/VueAtomes.vue'),
    'VueLayouts': () => import(/* webpackChunkName: "VueLayouts" */ '../../modules/dPdeveloppement/vue/VueLayouts/VueLayouts.vue'),
    'ChainingMain': () => import(/* webpackChunkName: "ChainingMain" */ '../../modules/chaining/vue/Components/ChainingMain/ChainingMain.vue'),
    'ChainingManagement': () => import(/* webpackChunkName: "ChainingManagement" */ '../../modules/chaining/vue/Components/ChainingManagement/ChainingManagement.vue'),
    'ChainingImport': () => import(/* webpackChunkName: "ChainingImport" */ '../../modules/chaining/vue/Components/ChainingImport/ChainingImport.vue'),
    'CodingInformationDashboard': () => import(/* webpackChunkName: "StRheaCoding" */ '../../modules/soins/vue/components/CodingInformationDashboard.vue'),
    'ChainingOperation': () => import(/* webpackChunkName: "ChainingOperation" */ '../../modules/chaining/vue/Components/ChainingOperation/ChainingOperation.vue'),
    'ChainingManagementContext': () => import(/* webpackChunkName: "ChainingManagementContext" */ '../../modules/chaining/vue/Components/ChainingManagementContext/ChainingManagementContext.vue'),
    'ChainingReplaceService': () => import(/* webpackChunkName: "ChainingReplaceService" */ '../../modules/chaining/vue/Components/ChainingReplaceService/ChainingReplaceService.vue'),
    'ChainingCareFollowUp': () => import(/* webpackChunkName: "ChainingCareFollowUp" */ '../../modules/chaining/vue/Components/ChainingCareFollowUp/ChainingCareFollowUp.vue'),
    'ChainingServiceLinkedStay': () => import(/* webpackChunkName: "ChainingServiceLinkedStay" */ '../../modules/chaining/vue/Components/ChainingServiceLinkedStay/ChainingServiceLinkedStay.vue'),
    'MedidataStatus': () => import(/* webpackChunkName: "MedidataStatus" */ '../../modules/medidata/vue/Components/MedidataStatus.vue'),
    'MedidataApiStatus': () => import(/* webpackChunkName: "MedidataStatus" */ '../../modules/medidata/vue/Components/MedidataApiStatus.vue'),
}
